<template>
  <v-container>
    <v-card class="p-2">
      <v-card-title>
        Shopify Collections
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search_keyword"
          :append-icon="icons.mdiMagnify"
          label="Search Keyword"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="collections" :search="search_keyword">
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-for="item in items" :key="item.id">
              <td>
                <i>#{{ item.remote_id }}</i>
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.tally }}</td>
              <td>
                <v-chip small class="ma-2" color="green" text-color="white">{{ item.type }}</v-chip>
              </td>
              <td>
                <router-link :to="{ name: 'shopify-collections-products', params: { collection_id: item.id } }">
                  <v-btn small color="primary darken-1" class="table-action--button btn-xs">
                    View Products
                  </v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import store from '@/store'
import { mdiMagnify } from '@mdi/js'
export default {
  data() {
    return {
      loading_data: false,
      shop: null,
      search_keyword: null,
      headers: [
        {
          text: 'Remote ID',
          value: 'remote_id',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Products Count',
        },
        {
          text: 'Type',
        },
        {
          text: 'Actions',
        },
      ],
      icons: {
        mdiMagnify,
      },
      collections: [],
    }
  },

  mounted() {
    this.shop = store.getters.loggedInShop
    this.fetchShopifyCollections()
  },

  methods: {
    async fetchShopifyCollections() {
      try {
        this.loading_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-shopify-collections`)
        if (response.data.success) {
          this.collections = response.data.data
        }
      } catch (e) {
      } finally {
        this.loading_data = false
      }
    },
  },
}
</script>